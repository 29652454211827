(() => {
  const timerDiv = document.getElementsByClassName("timer");
  if (timerDiv) {
    setInterval(() => {
      const start = new Date('2020-06-25T07:08:00Z');
      const difference = new Date() - start;
      const days = Math.trunc(difference / 24 / 3600 / 1000);
      const hours = Math.trunc((difference / 3600 / 1000) % 24);
      const minutes = Math.trunc((difference / 60 / 1000) % 60);
      const seconds = Math.trunc((difference / 1000) % 60);
      const millis = difference % 1000;

      timerDiv[0].innerHTML = "<span class='days'>" + days + " days</span><br>" + hours + " hours<br>" + minutes + " minutes<br>" + seconds.toString().padStart(2, '0') + " seconds<br>" + millis.toString().padStart(3, '0') + " millis";
    }, 33);
  }



})();
